<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_button_group vg_mtb_16">
        <el-date-picker
          size="small"
          class="topDateRangeSearch vg_mr_8"
          v-model="dateRange1"
          :picker-options="pickerOptions"
          type="monthrange"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="统计录入开始月份"
          end-placeholder="统计录入结束月份"
        />
        <el-button size="small" type="primary" icon="el-icon-search" @click="getTableData()" class="vg_mt_8">查询 </el-button>
        <el-button type="info" size="small" class="vg_mt_8" @click="buttonRefresh1()" icon="el-icon-refresh-right"> 刷新</el-button>
        <el-button type="success" size="small" class="vg_mt_8" @click="exportExcel()">导出Excel</el-button>
      </div>
      <DynamicUTable
        id="multiTable"
        ref="multiTable"
        :tableData="tableData"
        :columns="tableProperties"
        :total-page="totalPage"
        v-loading="loadFlag1"
        :need-search="true"
        :need-check-box="false"
        :need-fixed-height="true"
        :page-size="50"
        @getTableData="getTableData"
      >
      </DynamicUTable>
    </el-card>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { downloadFile, searchTimeRangeFormatter } from '@api/public';
import { getDateNoTime } from '@assets/js/dateUtils';
import { barkOrdersProperties } from '@/views/SalesManagement/SconManage/scon';
import { sconAPI } from '@api/modules/scon';

export default {
  name: 'DataList',
  components: {
    DynamicUTable
  },
  data() {
    return {
      activeName: 'first',
      dateRange1: [],
      tableProperties: cloneDeep(barkOrdersProperties),
      tableData: [],
      loadFlag1: true,
      totalPage: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      }
    };
  },
  computed: {},
  mounted() {
    this.initData();
  },
  methods: {
    async initData() {
      await this.changeProperties();
      await this.getTableData();
    },
    async changeProperties() {},
    getTableData() {
      this.loadFlag1 = true;
      sconAPI.getSconProdCountList(searchTimeRangeFormatter(this.$refs.multiTable.searchForm, [this.dateRange1])).then(({ data }) => {
        this.tableData = data.list;
        this.totalPage = data.total;
        this.loadFlag1 = false;
      });
    },
    buttonRefresh1() {
      this.dateRange1 = [];
      this.$refs.multiTable.resetFields();
      this.getTableData();
    },
    exportExcel() {
      let requestBody = { type: 1, ...this.$refs.multiTable.searchForm };
      sconAPI.exportSconProdCount(requestBody).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: 'BarkOrders' + getDateNoTime(Date.now(), false) });
      });
    },
    saveRow(row, index) {
      sconAPI.editSconPartCount(row).then(({ data }) => {
        this.$message.success('保存成功!');
      });
    }
  }
};
</script>

<style scoped>
.omitWithoutLineBreaks {
  white-space: nowrap; /* 不换行 */
  overflow: hidden; /* 隐藏超出部分 */
  text-overflow: ellipsis; /* 当文本溢出时，显示省略号 */
}
</style>
